import React, { useEffect, useState } from "react";
import { ModalHeader, ModalBody, Input } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { parseDate } from "../utils/date";
import Layout from "../components/layout/layout";
import StyledLoading from "../components/styled/StyledLoading";
import { StyledForm, StyledModal, StyledTable } from "../components/styled/BlacklistStyles";
import StyledButton from "../components/styled/StyledButton";
import blacklistSelectors from "../redux/blacklist/selectors";
import blacklistActions from "../redux/blacklist/actions";
import reduxLoaderSelectors from "../redux/redux-loader/selectors";
import { StyledToolbar } from "../components/styled/ToolbarStyles";
import { ToolbarBox } from "../components/styled/DashboardStyles";

const Blacklist = ({ blacklist, error, getBlacklist, deleteBlacklistItem, postBlacklistItem, getBlacklistState: { isLoading } }) => {
  const [isModalOpen, setModal] = useState(false);
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("ip");
  useEffect(() => {
    if (!isLoading && blacklist.length === 0) {
      getBlacklist();
    }
  }, [blacklist.length, getBlacklist, isLoading]);

  const addData = async (e) => {
    e.preventDefault();
    const JSONData = JSON.stringify({
      type,
      value,
      description: description || "-",
    });
    await postBlacklistItem(JSONData);
    getBlacklist();
    setModal(false);
    if (error === null) {
      setType("ip");
      setValue("");
      setDescription("");
    }
  };

  const deleteAddress = async (id) => {
    await deleteBlacklistItem(id);
    getBlacklist();
  };

  const renderRow = (accountData) => {
    const { id, type, value, description, creation_date } = accountData;
    return (
      <tr key={id}>
        <td>{id}</td>
        <td>{type}</td>
        <td>{value}</td>
        <td>{description}</td>
        <td>{parseDate(creation_date)}</td>
        <td style={{ width: "104px" }}>
          <StyledButton as="button" className="table-button" onClick={() => deleteAddress(id)} secondary>
            Delete
          </StyledButton>
        </td>
      </tr>
    );
  };

  return (
    <>
      {isLoading ? (
        <StyledLoading />
      ) : (
        <>
          <StyledToolbar>
            <ToolbarBox>
              <p>Blacklist</p>
              <p>{blacklist.length}</p>
              <StyledButton as="button" onClick={() => setModal(true)}>
                Add
              </StyledButton>
            </ToolbarBox>
          </StyledToolbar>
          <StyledModal isOpen={isModalOpen} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)}>Add Address</ModalHeader>
            <ModalBody>
              <StyledForm onSubmit={(e) => addData(e)}>
                <label htmlFor="type">
                  Type:
                  <select name="type" id="type" required onChange={(e) => setType(e.target.value)} defaultValue="ip">
                    <option value={type}>ip</option>
                  </select>
                </label>
                <label htmlFor="value">
                  Value:
                  <Input id="value" type="text" name="value" value={value} onChange={(e) => setValue(e.target.value)} required />
                </label>
                <label htmlFor="desc">
                  <p style={{ margin: 0 }}>
                    Description: <span style={{ fontSize: "10px", display: "block" }}>(optional)</span>
                  </p>
                  <Input id="desc" type="text" name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                </label>
                <StyledButton as="button" secondary type="submit" style={{ margin: "35px auto 0", border: "none" }}>
                  Create
                </StyledButton>
              </StyledForm>
            </ModalBody>
          </StyledModal>
          <StyledTable hover style={{ width: "calc(100% - 30px)", marginTop: 16 }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Value</th>
                <th>Description</th>
                <th>Creation Date</th>
                <th style={{ textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {
                blacklist.length > 0 && blacklist.map((accountData) => renderRow(accountData))
                // .slice(currentPage * 10, currentPage * 10 + 10)
              }
            </tbody>
          </StyledTable>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    blacklist: blacklistSelectors.getBlacklist(state),
    error: blacklistSelectors.getBlacklistErrors(state),
    getBlacklistState: reduxLoaderSelectors.getLoadingState(state, blacklistActions.getBlacklist),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBlacklist: blacklistActions.getBlacklist,
      deleteBlacklistItem: blacklistActions.deleteBlacklistItem,
      postBlacklistItem: blacklistActions.postBlacklistItem,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout(Blacklist));
