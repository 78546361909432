import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountSelectors, accountActions } from "../../redux/accounts/index";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import { ExtendStyledTable } from "../tables/styles";
import { Box } from "./Info";

function formatBytes(bytes,decimals) {
    if(bytes === 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatDefault(x) {
    const numberFormat1 = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 4,
    });
    return numberFormat1.format(x).replaceAll(",", " ");
}

const formatters = {
    "statistics.total_size.max": formatBytes,
    "statistics.total_events_size.max": formatBytes,
    "statistics.total_page_size.max": formatBytes,
    "statistics.total_size.sum": formatBytes,
    "statistics.total_events_size.sum": formatBytes,
    "statistics.total_page_size.sum": formatBytes,
}

const ESstats = ({ accountID, esStats, getEsStats, esStatsState: { isDone } }) => {
  useEffect(() => {
    if (esStats === undefined) {
      getEsStats(accountID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderTableData = (data) => {
    const renderBackground = (acc, cluster) => {
      if (acc) {
        if (acc < cluster) return "#4caf50";
        if (acc > cluster) return "orange";
        else return "#302f35";
      }
    };

    const computeValue = (account, cluster) => {
      if (account) {
        return ` (${((account / cluster) * 100).toFixed(2)}%)`;
      }
      return;
    };

    function format(key, value) {
        const fn = formatters[key] || formatDefault

        return fn && fn(value)
    }

    return data !== null && esStats.total !== undefined ? (
      data.map((item) => {
        const valComputed = computeValue(esStats.stats !== null && esStats.stats[item[0]], item[1]);
        return (
          <tr>
            <td>{item[0]}</td>
            <td
              style={{
                backgroundColor: item[0].includes("avg") ? renderBackground(esStats.stats !== null && esStats.stats[item[0]], item[1]) : "initial",
                color: renderBackground(esStats.stats !== null && esStats.stats[item[0]], item[1]) === "orange" ? "#000" : "#fff",
              }}
            >
              {(esStats.stats !== null && format(item[0], esStats.stats[item[0]])) || "No data"}
              {(item[0].includes("sum") || item[0].includes("total")) && valComputed && <span style={{ color: valComputed.includes("100") ? "#4caf50" : "orange" }}>{valComputed}</span>}
            </td>
            <td>{format(item[0], item[1])}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <th>No data</th>
        <td>No data</td>
        <td>No data</td>
      </tr>
    );
  };

  const detailedEventStats = [];
  const restStats = [];

  if (esStats?.toString) {
    Object.entries(esStats.total).forEach(([key, value]) => {
      if (key.match(/event\..*\.*/)) {
        detailedEventStats.push([key, value]);
        return;
      }
      restStats.push([key, value]);
    });
  }

  return (
    <Box>
      <h2>Statistics</h2>
      {esStats === undefined ? (
        <p>{isDone ? "Failed to fetch :(" : "Loading..."}</p>
      ) : (
        <div>
          <ExtendStyledTable>
            <thead>
              <tr>
                <th></th>
                <th>Account value</th>
                <th>Cluster value</th>
              </tr>
            </thead>
            <tbody>
              {renderTableData(restStats)}
              {renderTableData(detailedEventStats)}
            </tbody>
          </ExtendStyledTable>
        </div>
      )}
    </Box>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      esStats: accountSelectors.getESStats(state),
      esStatsState: reduxLoaderSelectors.getLoadingState(state, accountActions.getEsStats),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getEsStats: accountActions.getEsStats,
      },
      dispatch
    );
  }
)(ESstats);
