import { handleActions } from "redux-actions";
import accountActions from "./actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";

const defaultState = {
  list: [],
  count: 0,
  error: "",
};
const campaignsReducer = handleActions(
  {
    [accountActions.GET_CAMPAIGNS_SUCCESS]: (state, action) => {
      const { campaigns } = action.payload;
      if (campaigns?.length === 0) cogoToast.error("Nothing found");
      return update(state, { list: { $set: campaigns }, count: { $set: campaigns.length } });
    },
  },
  defaultState
);

export default campaignsReducer;
