import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const campaignsActions = {
  ...createActions({
    GET_CAMPAIGNS: () => ({}),
  }),
  ...createRequestAction("GET_CAMPAIGNS"),
};

export default campaignsActions;
