import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import rootSaga from "./sagas";

import userReducer from "./user/reducer";
import accountsReducer from "./accounts/reducer";
import blacklistReducer from "./blacklist/reducer";
import clustersReducer from "./clusters/reducer";
import partnersReducer from "./partners/reducer";
import integrationsReducer from "./integrations/reducer";
import recordingSaga from "./recording/reducer";
import loaderReducer from "./redux-loader/reducer";
import campaignsReducer from "./campaigns/reducer";

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunkMiddleware, sagaMiddleware];

if (process.env.REACT_APP_ENV !== "PRODUCTION") {
  middlewares.push(loggerMiddleware);
}

const initialState = {};

const store = createStore(
  combineReducers({
    reduxLoader: loaderReducer,
    accounts: accountsReducer,
    user: userReducer,
    blacklist: blacklistReducer,
    clusters: clustersReducer,
    partners: partnersReducer,
    integrations: integrationsReducer,
    campaigns: campaignsReducer,
    recording: recordingSaga,
  }),
  initialState,
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default store;
