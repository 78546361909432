const getCampaignsList = (state) => {
  return state.campaigns.list;
};

const getCampaignsErrors = (state) => {
  return state.campaigns.error;
};

const getCampaignsCount = (state) => {
  return state.campaigns.count;
};
export default {
  getCampaignsList,
  getCampaignsErrors,
  getCampaignsCount,
};
