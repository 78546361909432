import React from "react";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import withAuthentication from "./components/mixins/withAuthentication";

import LoginView from "./containers/LoginView";
import DashboardView from "./containers/DashboardView";
import AccountDetailsView from "./containers/AccountDetailsView";
import Blacklist from "./containers/Blacklist";
import Clusters from "./containers/Clusters";
import Partners from "./containers/Partners";
import Integrations from "./containers/Integrations";
import Campaigns from "./containers/Campaigns";
import Tools from "./containers/Tools";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

const ProtectedDashboard = withAuthentication(DashboardView);
const ProtectedAccountDetails = withAuthentication(AccountDetailsView);
const ProtectedBlacklist = withAuthentication(Blacklist);
const ProtectedClusters = withAuthentication(Clusters);
const ProtectedTools = withAuthentication(Tools);
const ProtectedPartners = withAuthentication(Partners);
const ProtectedCampaigns = withAuthentication(Campaigns);
const ProtectedIntegrations = withAuthentication(Integrations);

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <>
          <Route exact path="/" component={ProtectedDashboard} />
          <Route exact path="/signin" component={LoginView} />
          <Route exact path="/blacklist" component={ProtectedBlacklist} />
          <Route exact path="/clusters" component={ProtectedClusters} />
          <Route exact path="/campaigns" component={ProtectedCampaigns} />
          <Route exact path="/partners" component={ProtectedPartners} />
          <Route exact path="/integrations" component={ProtectedIntegrations} />
          <Route path="/accounts/:id" component={({ match }) => <ProtectedAccountDetails match={match} />} />
          <Route exact path="/tools" component={ProtectedTools} />
        </>
      </Router>
    </Provider>
  );
};

export default App;
