import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import Layout from "../components/layout/layout";
import StyledButton from "../components/styled/StyledButton";
import { partnersSelectors, partnersActions } from "../redux/partners";
import { reduxLoaderSelectors } from "../redux/redux-loader";
import { StyledTable } from "../components/styled/BlacklistStyles";
import DateParagraph from "../components/styled/DateParagraph";
import StyledLoading from "../components/styled/StyledLoading";
import { ResetButton, ToolbarBox } from "../components/styled/DashboardStyles";
import { StyledToolbar } from "../components/styled/ToolbarStyles";

const Partners = ({ getPartners, list, count, currentPage, setCurrentPage, limit, getPartnersState: { isDone, isLoading } }) => {
  const { search } = useLocation();

  const pullData = useCallback(
    ({ page = currentPage }) => {
      return getPartners(page * limit);
    },
    [currentPage, getPartners, limit]
  );

  useEffect(() => {
    const params = queryString.parse(search);
    const page = params.page && parseInt(params.page) - 1;
    if (search.length > 0 && isDone && list.length === 0) return;
    if (isEmpty(params)) {
      window.history.pushState("", "", `/partners?page=${currentPage + 1}`);
    }
    if (params.page) {
      setCurrentPage(page);
      pullData({ page });
    } else {
      if (list.length > 0) return;
      setCurrentPage(0);
      pullData({ page: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeader = () => (
    <thead>
      <tr>
        <th>Partner ID</th>
        <th>Comission</th>
        <th>Status</th>
        <th>Creation date </th>
        <th>Accounts count</th>
        <th>Payments count</th>
        <th>Withdrawals count</th>
        <th>Payments sum eur</th>
        <th>Payments sum usd</th>
        <th>Payments sum pln</th>
      </tr>
    </thead>
  );

  const renderRow = ({ partner_id, commission, status, creation_date, accounts_count, payments_count, withdrawals_count, payments_sum_eur, payments_sum_usd, payments_sum_pln }) => (
    <tr key={partner_id}>
      <td>
        <Link to={`/?page=1&filter_type=partner_id&filter_value=${partner_id}&sortby=creation_date&order=DESC&value=`}>{partner_id}</Link>
      </td>
      <td>{commission}</td>
      <td>{status}</td>
      <td style={{ width: 150 }}>
        <DateParagraph data={creation_date} size="12" />
      </td>
      <td>{accounts_count}</td>
      <td>{payments_count}</td>
      <td>{withdrawals_count}</td>
      <td>{payments_sum_eur}</td>
      <td>{payments_sum_usd}</td>
      <td>{payments_sum_pln}</td>
    </tr>
  );

  const handlePage = async (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          const newPage = currentPage - 1;
          await setCurrentPage(newPage);
          pullData({ page: newPage });
          break;
        }
        break;
      case "next":
        if (Math.floor(count / limit) >= currentPage + 1) {
          const newPage = currentPage + 1;
          await setCurrentPage(newPage);
          pullData({ page: newPage });
        }
        break;
      default:
        return null;
    }
  };

  return (
    <div>
      <StyledToolbar>
        <ToolbarBox>
          <p>Partners</p>
          <p>{count}</p>
        </ToolbarBox>
      </StyledToolbar>

      {list.length > 0 && (
        <>
          <div style={{ overflowX: "auto", maxWidth: 1600, padding: "15px 0" }} className="mx-auto">
            <StyledTable style={{ minWidth: 1200 }} hover>
              {renderHeader()}
              <tbody>{list.map((partnerData) => renderRow(partnerData))}</tbody>
            </StyledTable>
          </div>
          <div
            style={{
              marginBottom: 48,
              display: "grid",
              gridTemplateColumns: "140px 100px 140px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledButton as="button" onClick={() => handlePage("prev")} disabled={currentPage === 0}>
              Previous page
            </StyledButton>
            {count && (
              <div style={{ margin: "0 10px", textAlign: "center" }}>
                {currentPage + 1} of {Math.ceil(count / limit)}
              </div>
            )}
            <StyledButton onClick={() => handlePage("next")} as="button" disabled={Math.floor(count / limit) < currentPage + 1}>
              Next page
            </StyledButton>
          </div>
        </>
      )}
      {isDone && list.length === 0 && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
          <p style={{ margin: "0 16px 0 0" }}>
            No results{" "}
            <span role="img" aria-label="emoji">
              💁‍♂️
            </span>
          </p>{" "}
          <ResetButton
            to="/"
            onClick={() => {
              setCurrentPage(0);
              return pullData({ page: 0 });
            }}
          >
            Reset
          </ResetButton>
        </div>
      )}
      {isLoading && <StyledLoading />}
    </div>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      list: partnersSelectors.getPartnersList(state),
      count: partnersSelectors.getPartnersCount(state),
      currentPage: partnersSelectors.getCurrentPage(state),
      limit: partnersSelectors.getLimit(state),
      getPartnersState: reduxLoaderSelectors.getLoadingState(state, partnersActions.getPartners),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getPartners: partnersActions.getPartners,
        setCurrentPage: partnersActions.currentPartnersPage,
      },
      dispatch
    );
  }
)(Layout(Partners));
