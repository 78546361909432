import React, { useState } from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, Modal } from "reactstrap";
import StyledButton from "../styled/StyledButton";
import { connect } from "react-redux";
import { accountActions } from "../../redux/accounts";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import { parseDateToForm } from "../../utils/date";
import { formatFromNow } from "../../utils/formatter";
import * as Styled from "../styled/FormStyles";
import { reduxLoaderSelectors } from "../../redux/redux-loader";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
`;

export const StyledModal = styled(Modal)`
  .modal-header {
    padding: 1rem 2rem;
  }
  button.close span {
    color: red;
    text-shadow: none;
    font-size: 35px;
  }
  .modal-title {
    line-height: 38px;
  }
  .modal-body {
    padding: 2rem 1rem;
  }
`;

const UpdateAccount = ({
  currency,
  endDate,
  status,
  setOpen,
  isOpen,
  accountID,
  updateAccount,
  getUpdateAccountState: { isLoading, isDone, isError },
}) => {
  const [currentCurrency, setCurrency] = useState(currency);
  const [currentStatus, setStatus] = useState(status);
  const [currentEndDate, setEndDate] = useState(endDate);

  const addData = async (e) => {
    e.preventDefault();
    try {
      const JSONData = JSON.stringify({
        status: currentStatus,
        currency: currentCurrency,
        end_date: currentEndDate,
      });
      await updateAccount(JSONData, accountID);
    } catch (e) {
      if (isError) console.error(e);
    } finally {
      if (isDone && !isError) setOpen(false);
    }
  };
  return (
    <StyledModal
      isOpen={isOpen}
      toggle={() => setOpen(!isOpen)}
      modalTransition={{ timeout: 20 }}
      backdropTransition={{ timeout: 20 }}
    >
      <ModalHeader toggle={() => setOpen(!isOpen)}>Update account</ModalHeader>
      <ModalBody>
        <StyledForm onSubmit={(e) => addData(e)}>
          <Styled.LabelSelect htmlFor="status">
            <span>Status:</span>
            <select
              name="status"
              id="status"
              required
              onChange={(e) => setStatus(e.target.value)}
              defaultValue={currentStatus}
            >
              <option value="active">Active</option>
              <option value="not_active">Not active</option>
              <option value="blocked">Blocked</option>
              <option value="deleted">Deleted</option>
              <option value="paused">Paused</option>
            </select>
          </Styled.LabelSelect>
          <Styled.LabelInput htmlFor="end_date" style={{ position: "relative", zIndex: 99 }}>
            <span style={{ alignSelf: "flex-start", marginTop: 4 }}>End date:</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <DatePicker
                selected={parseDateToForm(currentEndDate)}
                onChange={(date) => setEndDate(date.toISOString())}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="dd/MM/yyyy HH:mm"
              />
              <p style={{ margin: "4px 0 0 4px", fontSize: 12, opacity: 0.5 }}>
                {formatFromNow(currentEndDate, false, true)}
              </p>
            </div>
          </Styled.LabelInput>
          <Styled.LabelSelect htmlFor="currency">
            <span>Currency:</span>
            <select
              name="currency"
              id="currency"
              required
              onChange={(e) => setCurrency(e.target.value)}
              defaultValue={currentCurrency}
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="PLN">PLN</option>
            </select>
          </Styled.LabelSelect>
          <StyledButton
            loading
            isLoading={isLoading}
            as="button"
            secondary
            type="submit"
            style={{ margin: "35px auto 0", border: "none" }}
          >
            Update
          </StyledButton>
        </StyledForm>
      </ModalBody>
    </StyledModal>
  );
};

export default connect(
  (state) => (state) => {
    return {
      getUpdateAccountState: reduxLoaderSelectors.getLoadingState(state, accountActions.updateAccount),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        updateAccount: accountActions.updateAccount,
      },
      dispatch
    );
  }
)(UpdateAccount);
