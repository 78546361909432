import React from "react";
import { Link } from "react-router-dom";
import { Collapse, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import logo from "../../img/logo_livesession_light.svg";
import { bindActionCreators } from "redux";
import Button, { StyledButton } from "../styled/StyledButton";
import { withRouter } from "react-router-dom";
import { userActions } from "../../redux/user";
import { RiAccountBoxLine, RiFileListLine, RiToolsFill } from "react-icons/ri";
import { AiOutlineCluster } from "react-icons/ai";
import { MdPeopleOutline } from "react-icons/md";
import { IoCartOutline } from "react-icons/io5";
import { VscDebugDisconnect } from "react-icons/vsc";

const StyledBackground = styled.div`
  background-color: #252529;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
`;

const StyledNavbar = styled.div`
  padding: 0 15px !important;
  height: 70px;
  width: 95%;
  margin: 0 auto;
  max-width: 1600px;
  .logo-box {
    .crm {
      display: inline-block;
      margin: 0 0 0 10px;
      transform: translate(-15%, -25%);
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #fff;
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  & a {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
    .logo {
      max-width: 120px;
    }
  }
  & div {
    height: 100%;
    display: none;
    margin-left: 35px;
    display: flex;
    align-items: center;
    @media (min-width: 992px) {
      display: flex;
    }
  }
`;

const StyledMobileNav = styled(Nav)`
  width: 35%;
  position: relative;
  z-index: 20;
  button,
  a {
    padding: 10px auto;
  }
  button {
    justify-content: center;
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  ${StyledButton} {
    text-transform: inherit;
    margin-right: 16px;
  }
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const ExtendNavButton = styled(StyledButton)`
  flex-direction: column;
  max-height: 100%;
  & svg {
    font-size: 18px;
    margin-bottom: 4px;
    margin-right: 0;
  }
  &:hover {
    & svg {
      fill: #fff;
    }
  }
`;

class NavbarLayout extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  pullData = (page = this.props.currentPage, filterValue = "", filterType = "") => {
    const { getAccounts } = this.props;
    const { searchQuery, sort, sortOrder } = this.state;
    return getAccounts(searchQuery, sort, sortOrder, page * 10, filterType, filterValue);
  };

  onSearchKeyPress = async (e) => {
    const { searchEmail, setCurrentPage } = this.props;
    const { searchQuery } = this.state;
    if (e.key === "Enter") {
      setCurrentPage(0);
      await searchEmail(searchQuery);
      this.pullData();
    }
  };

  render() {
    const regex = /[^]*\s./;
    const { pathname } = this.props.location;
    const { displayName, photoURL } = this.props.user;
    const name = displayName.match(regex);
    const { isOpen } = this.state;
    return (
      <StyledBackground>
        <StyledNavbar className="navbar navbar-expand-lg navbar-dark">
          <NavbarBrand tag="div" color="light" href="/" className="ml-2 logo-box" style={{ padding: 0, height: "100%" }}>
            <StyledContainer>
              <Link to="/">
                <img src={logo} alt="logo" className="logo img-fluid" />
                <p className="crm">CRM</p>
              </Link>
              <div>
                <Button as={ExtendNavButton} href="/" className={["btn-navbar", (pathname === "/" || pathname.includes("/accounts/")) && "active"]}>
                  <RiAccountBoxLine />
                  Accounts
                </Button>
                <Button as={ExtendNavButton} href="/partners" className={["btn-navbar", pathname === "/partners" && "active"].join(" ")}>
                  <MdPeopleOutline />
                  Partners
                </Button>
                <Button as={ExtendNavButton} href="/integrations" className={["btn-navbar", pathname === "/integrations" && "active"].join(" ")}>
                  <VscDebugDisconnect />
                  Integrations
                </Button>
                <Button as={ExtendNavButton} href="/campaigns" className={["btn-navbar", pathname === "/campaigns" && "active"].join(" ")}>
                  <IoCartOutline />
                  Campaigns
                </Button>
                <Button as={ExtendNavButton} href="/blacklist" className={["btn-navbar", pathname === "/blacklist" && "active"].join(" ")}>
                  <RiFileListLine />
                  Blacklist
                </Button>
                <Button as={ExtendNavButton} href="/clusters" className={["btn-navbar", pathname === "/clusters" && "active"].join(" ")}>
                  <AiOutlineCluster />
                  Clusters
                </Button>
                <Button as={ExtendNavButton} href="/tools" className={["btn-navbar", pathname === "/tools" && "active"].join(" ")}>
                  <RiToolsFill />
                  Tools
                </Button>
              </div>
            </StyledContainer>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar style={{ justifyContent: "flex-end" }}>
            <StyledMobileNav navbar style={{ justifyContent: "flex-end" }}>
              <User>
                <Button as="button" onClick={this.props.signOut} className="btn-navbar logout">
                  {` Hi ${name}. (Log out)`}
                </Button>
                <Avatar src={photoURL} alt="avatar" />
              </User>
            </StyledMobileNav>
          </Collapse>
        </StyledNavbar>
      </StyledBackground>
    );
  }
}

export default connect(
  null,
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        signOut: userActions.userSignOut,
      },
      dispatch
    );
  }
)(withRouter(NavbarLayout));
