import { auth } from "../firebase";

const baseURL = `${process.env.REACT_APP_API_URL}`;

export const getRaw = (url) =>
    auth.currentUser.getIdToken(false).then(async (token) => {
        const response = await fetch(`${baseURL}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    });

export const get = (url) =>
  auth.currentUser.getIdToken(false).then(async (token) => {
    const response = await fetch(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
    return response;
  });

export const post = (url, body) =>
  auth.currentUser.getIdToken(false).then(async (token) => {
    const response = await fetch(`${baseURL}${url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    }).then(async (res) => {
        if (res.status >= 400 && res.status < 600) {
            // TODO: error message
            throw new Error("something went wrong");
        }
        return res.json();
    });
    return response;
  });

export const put = (url, body) =>
  auth.currentUser.getIdToken(false).then(async (token) => {
    const response = await fetch(`${baseURL}${url}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    }).then((res) => res.json());
    return response;
  });

export const deleteItem = (url, body) =>
  auth.currentUser.getIdToken(false).then(async (token) => {
    const response = await fetch(`${baseURL}${url}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
    return response;
  });

export default {
  get: get,
  post: post,
  put: put,
  delete: deleteItem,
};
