import React, { useEffect } from "react";
import { connect } from "react-redux";
import Layout from "../components/layout/layout";
import { ToolbarBox } from "../components/styled/DashboardStyles";
import { StyledToolbar } from "../components/styled/ToolbarStyles";
import { campaignsSelectors, campaignsActions } from "../redux/campaigns";
import StyledLoading from "../components/styled/StyledLoading";
import { reduxLoaderSelectors } from "../redux/redux-loader";
import DateParagraph from "../components/styled/DateParagraph";
import { StyledTable } from "../components/styled/BlacklistStyles";
import { bindActionCreators } from "redux";

const Campaigns = ({ list, getCampaigns, count, getCampaignsState: { isDone, isLoading } }) => {
  useEffect(() => {
    if (!isDone || list.lenght === 0) {
      getCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderHeader = () => (
    <thead>
      <tr>
        <th>Campaign ID</th>
        <th>Discount Code</th>
        <th>Plan</th>
        <th>Redemptions Limit</th>
        <th>Sessions</th>
        <th>Trial Days</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
  );
  const renderRow = ({ code, discount_code, plan, redemptions_limit, sessions, trial_days, expiration_date }) => (
    <tr>
      <td>{code}</td>
      <td>{discount_code}</td>
      <td>{plan}</td>
      <td>{redemptions_limit}</td>
      <td>{sessions}</td>
      <td>{trial_days}</td>
      <td style={{ width: 150 }}>
        <DateParagraph data={expiration_date} size="12" />
      </td>
    </tr>
  );
  return (
    <>
      <StyledToolbar>
        <ToolbarBox>
          <p>Campaigns</p>
          <p>{count}</p>
        </ToolbarBox>
      </StyledToolbar>
      <div style={{ overflowX: "auto", maxWidth: 1600, padding: "15px 0" }} className="mx-auto">
        <StyledTable style={{ minWidth: 1200 }} hover>
          {renderHeader()}
          <tbody>{list.map((campaignsData) => renderRow(campaignsData))}</tbody>
        </StyledTable>
      </div>
      {isLoading && <StyledLoading />}
    </>
  );
};
export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      list: campaignsSelectors.getCampaignsList(state),
      errors: campaignsSelectors.getCampaignsErrors(state),
      count: campaignsSelectors.getCampaignsCount(state),
      getCampaignsState: reduxLoaderSelectors.getLoadingState(state, campaignsActions.getCampaigns),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getCampaigns: campaignsActions.getCampaigns,
      },
      dispatch
    );
  }
)(Layout(Campaigns));
